<template>
    <div class="section">
        <page-title :title="title"></page-title>
        <b-card :title="$t('sst.pages.dashboard.title')" title-tag="h5" class="mb-3">
            <spinner v-if="refreshData"></spinner>
            <div v-if="!refreshData">
                <b-row>
                    <b-col class="d-none d-lg-block pt-2" lg="4" xxl="6">
                        <div class="legend">
                            <div class="legend-section">
                                <h4>{{ $t('sst.pages.dashboard.items.categories.market.name') }}</h4>

                                <ul>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.market.subCategories.competitive') }}</li>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.market.subCategories.quotationRate') }}</li>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.market.subCategories.transformationRate') }}</li>
                                </ul>
                            </div>

                            <div class="legend-section">
                                <h4>{{ $t('sst.pages.dashboard.items.categories.timeLimit.name') }}</h4>

                                <ul>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.timeLimit.subCategories.reactivity') }}</li>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.timeLimit.subCategories.quotationDeadline') }}</li>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.timeLimit.subCategories.leadTime') }}</li>
                                </ul>
                            </div>

                            <div class="legend-section">
                                <h4>{{ $t('sst.pages.dashboard.items.categories.quality.name') }}</h4>

                                <ul>
                                    <li>{{ $t('sst.pages.dashboard.items.categories.quality.subCategories.qualityLevel') }}</li>
                                </ul>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="pt-2" md="6" lg="4" xxl="3" v-if="data.yearly">
                        <sst-card
                            :title="$t('sst.pages.dashboard.cards.yearly.title')"
                            :subtitle="$t('sst.pages.dashboard.cards.yearly.subtitle')"
                            :rate="false"
                            :cssClassModifier="'yearly'"
                            :data="data.yearly"
                            :yearlyData="data.yearly"
                        ></sst-card>
                    </b-col>
                    <b-col class="pt-2" md="6" lg="4" xxl="3" v-if="data.monthly">
                        <sst-card
                            :title="$t('sst.pages.dashboard.cards.monthly.title')"
                            :subtitle="$t('sst.pages.dashboard.cards.monthly.subtitle')"
                            :rate="true"
                            :cssClassModifier="'monthly'"
                            :data="data.monthly"
                            :yearlyData="data.yearly"
                        ></sst-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right pt-2" md="12" lg="12" xxl="12">
                        <small class="text-muted">
                            {{
                                $t('sst.pages.dashboard.labels.info', {
                                    yearlyPurchaseCount: data.yearly.purchase_count,
                                    monthlyPurchaseCount: data.monthly.purchase_count
                                })
                            }}
                        </small>
                        <br />
                        <small class="text-muted" v-if="data.yearly.global_rating.value <= data.yearly.best_rating">
                            {{
                                $t('sst.pages.dashboard.labels.bestRating', {
                                    bestRating: data.yearly.best_rating
                                })
                            }}
                        </small>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle';
import SstCard from '@/components/sst/dashboard/SstCard';
import Spinner from '@/components/shared/element/Spinner';
import { SstRepository } from '@/repositories';

export default {
    name: 'SstDashboard',
    components: {
        PageTitle,
        SstCard,
        Spinner
    },
    data: () => ({
        title: 'Dashboard',
        refreshData: true,
        data: []
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.refreshData = true;
            SstRepository.getDashboardData()
                .then(response => {
                    this.refreshData = false;
                    this.data = response.data.data;
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        }
    }
};
</script>

<style scoped lang="scss">
// Legend
.legend {
    &-section {
        &:first-child {
            margin-top: 61px;
        }

        h4 {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700s;
            text-transform: uppercase;
            border-bottom: 3px solid gray('300');
            margin-bottom: 13px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-size: 13px;
                display: flex;
                width: 100%;
                min-height: 33px;
                align-items: center;
                justify-content: flex-end;

                & + li {
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>
