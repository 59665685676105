<template>
    <div class="page-title">
        <h1 class="page-title__primary">{{ title }}</h1>
        <h4 class="page-title__secondary">{{ subtitle }}</h4>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String
    }
};
</script>

<style scoped lang="scss">
.page-title {
    display: block;
    line-height: 1.25;
    margin-bottom: $spacer;

    &__primary {
        font-size: $h5-font-size;
        line-height: inherit;
        margin-bottom: 0;
    }

    &__secondary {
        font-size: 12px;
        line-height: inherit;
        font-weight: 400;
        color: gray('500');
        margin-bottom: 0;
    }
}
</style>
