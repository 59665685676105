<template>
    <b-list-group-item>
        <div class="sst-card-value" v-if="displayValue">{{ item.value }} {{ valueSymbol }}</div>
        <div class="sst-card-label">
            <span class="sst-card-label__placeholder d-lg-none">{{ label }}</span>
            <span :class="'sst-card-label__value dashboard-text-' + item.status.class">
                {{ item.status.label }}
            </span>
        </div>
        <div v-if="rate" class="sst-card-rate">
            <i class="fas fa-arrow-up dashboard-text-success" v-if="item.value > compareWith || item.value === compareWith"></i>
            <i class="fas fa-arrow-down dashboard-text-danger" v-else></i>
        </div>
    </b-list-group-item>
</template>

<script>
export default {
    name: 'SstCardItem',
    props: {
        rate: {
            type: Boolean,
            required: false
        },
        label: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        compareWith: {
            type: String,
            required: true
        },
        valueSymbol: {
            type: String,
            required: true
        },
        displayValue: {
            type: Boolean,
            required: false,
            default: true
        }
    }
};
</script>
