<template>
    <b-card class="sst-card" :class="`sst-card--${cssClassModifier}`">
        <div class="sst-card-header">
            <h4 class="sst-card-header__title">{{ title }}<br />{{ subtitle }}</h4>
            <div class="sst-card-header__score">
                <span class="dashboard-text-success">
                    {{ parseFloat(this.data.global_rating.value).toFixed(1) }}
                </span>
                / <span>{{ DASHBOARD_CONSTANTS.GLOBAL_RATING.DENOMINATOR }}</span>
            </div>
            <div v-if="rate" class="sst-card-rate">
                <i
                    class="fas fa-arrow-up dashboard-text-success"
                    v-if="
                        data.global_rating.value > yearlyData.global_rating.value ||
                            data.global_rating.value === yearlyData.global_rating.value
                    "
                >
                </i>
                <i class="fas fa-arrow-down dashboard-text-danger" v-else></i>
            </div>
        </div>

        <div class="sst-card-body">
            <div class="sst-card-divider d-lg-none">
                <div class="sst-card-divider__label">{{ $t('sst.pages.dashboard.items.categories.market.name') }}</div>
            </div>

            <b-list-group>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.market.subCategories.competitive')"
                    :item="data.competitivity"
                    :rate="rate"
                    :compare-with="yearlyData.competitivity.value"
                    :value-symbol="'%'"
                    :display-value="false"
                ></sst-card-item>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.market.subCategories.quotationRate')"
                    :item="data.quotation_rate"
                    :rate="rate"
                    :compare-with="yearlyData.quotation_rate.value"
                    :value-symbol="'%'"
                ></sst-card-item>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.market.subCategories.transformationRate')"
                    :item="data.accepted_quotation_rate"
                    :rate="rate"
                    :compare-with="yearlyData.accepted_quotation_rate.value"
                    :value-symbol="'%'"
                ></sst-card-item>
            </b-list-group>

            <div class="sst-card-divider">
                <div class="sst-card-divider__label d-lg-none">{{ $t('sst.pages.dashboard.items.categories.timeLimit.name') }}</div>
            </div>

            <b-list-group>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.timeLimit.subCategories.reactivity')"
                    :item="data.react_delay"
                    :rate="rate"
                    :compare-with="yearlyData.react_delay.value"
                    :value-symbol="'J'"
                ></sst-card-item>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.timeLimit.subCategories.quotationDeadline')"
                    :item="data.quotation_delay"
                    :rate="rate"
                    :compare-with="yearlyData.quotation_delay.value"
                    :value-symbol="'J'"
                ></sst-card-item>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.timeLimit.subCategories.leadTime')"
                    :item="data.intervention_delay"
                    :rate="rate"
                    :compare-with="yearlyData.intervention_delay.value"
                    :value-symbol="'J'"
                ></sst-card-item>
            </b-list-group>

            <div class="sst-card-divider">
                <div class="sst-card-divider__label d-lg-none">{{ $t('sst.pages.dashboard.items.categories.quality.name') }}</div>
            </div>
            <b-list-group>
                <sst-card-item
                    :label="$t('sst.pages.dashboard.items.categories.quality.subCategories.qualityLevel')"
                    :compare-with="yearlyData.quality.value"
                    :rate="rate"
                    :item="data.quality"
                    :value-symbol="'%'"
                ></sst-card-item>
            </b-list-group>
        </div>
    </b-card>
</template>

<script>
import SstCardItem from '@/components/sst/dashboard/SstCardItem';
import { DASHBOARD } from '@/constants/sst/dashboard';
export default {
    name: 'SstCard',
    components: { SstCardItem },
    props: {
        title: String,
        subtitle: String,
        rate: Boolean,
        cssClassModifier: {
            type: String,
            default: 'yearly'
        },
        data: Object,
        yearlyData: Object
    },
    data() {
        return {
            DASHBOARD_CONSTANTS: DASHBOARD
        };
    },
    methods: {}
};
</script>

<style lang="scss">
$sst-card-padding-x: 0.5rem;
$sst-card-padding-y: 0.5rem;
$sst-card-value-width: 70px;
$sst-card-value-height: 24px;
$sst-card-value-arrow-width: 10px;
$sst-card-header-bg-color: gray('300');
$sst-card-body-bg-color: lighten($sst-card-header-bg-color, 30%);
$sst-card-color: $body-color;
$dashboard-text-success: #35ff63;
$dashboard-text-warning: #ffd861;
$dashboard-text-danger: #ff6262;

@mixin sst-card-variant($modificator, $header-bg-color, $body-bg-color, $divider-color, $color) {
    .sst-card--#{$modificator} {
        $self: &;
        color: $color;

        .sst-card {
            &-header {
                background-color: $header-bg-color;
            }
            &-header:after {
                border-top-color: $header-bg-color;
            }
            &-body {
                background-color: $body-bg-color;
            }
            &-value {
                background-color: darken($body-bg-color, 10%);
            }
            &-rate {
                background-color: darken($body-bg-color, 10%);
            }
            &-value:before {
                border-color: darken($body-bg-color, 3%);
                border-left-color: darken($body-bg-color, 10%);
            }
            &-divider__label {
                background-color: $body-bg-color;
            }
            &-divider:before {
                background-color: $divider-color;
            }
        }

        .list-group-item {
            background-color: darken($body-bg-color, 3%);
        }
    }
}

.sst-card {
    $self: &;
    min-width: 280px;
    font-size: 14px;
    overflow: hidden;
    color: $sst-card-color;
    border: none;
    border-radius: 8px;

    .card-body {
        padding: 0;
    }

    &-header {
        position: relative;
        height: 80px;
        padding: $sst-card-padding-y $sst-card-padding-x;
        text-transform: uppercase;
        text-align: center;
        background-color: $sst-card-header-bg-color;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: $sst-card-header-bg-color transparent transparent transparent;
        }

        &__title {
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            margin: 0;
        }

        &__score {
            font-size: 24px;
            font-weight: 500;
        }

        #{$self}-rate {
            position: absolute;
            bottom: 10px;
            right: 12px;
            margin: 0;
        }
    }

    &-body {
        padding: $sst-card-padding-y $sst-card-padding-x;
        background-color: $sst-card-body-bg-color;

        @include media-breakpoint-up(md) {
            padding-top: $sst-card-padding-y * 2;
        }
    }

    &-label,
    &-value {
        display: block;
        line-height: 1.1;
    }

    &-label {
        margin: 0 auto;
        text-align: center;

        span {
            display: block;
        }

        &__placeholder {
            font-size: 80%;
        }
        &__value {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    &-value {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: $sst-card-value-height;
        padding: 2px 4px;
        padding-right: $sst-card-value-arrow-width;
        width: 100%;
        max-width: $sst-card-value-width;
        margin-left: -($sst-card-value-width);
        font-weight: 700;
        background-color: darken($sst-card-body-bg-color, 10%);
        white-space: nowrap;

        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-style: solid;
            border-width: $sst-card-value-height/2 0 $sst-card-value-height/2 $sst-card-value-arrow-width;
            border-color: darken($sst-card-body-bg-color, 3%);
            border-left-color: darken($sst-card-body-bg-color, 10%);
        }
    }

    &-rate {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $sst-card-value-height;
        width: 100%;
        max-width: $sst-card-value-height;
        margin-right: -($sst-card-value-height);
        background-color: darken($sst-card-body-bg-color, 10%);

        .fa-arrow {
            &-up {
                transform: rotate(45deg);
            }
            &-down {
                transform: rotate(-45deg);
            }
        }
    }

    &-divider {
        position: relative;
        margin: 0 $sst-card-padding-x * -1;
        padding: $sst-card-padding-y * 2 0;
        font-weight: 700;
        text-transform: uppercase;

        &__label {
            position: relative;
            display: inline-block;
            background-color: $sst-card-body-bg-color;
            padding: 0 8px;
            z-index: 1;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            z-index: 0;
            width: 100%;
            height: 3px;
            margin-top: -2px;
            background-color: darken($sst-card-body-bg-color, 3%);
        }
    }

    .list-group {
        &-item {
            display: flex;
            align-items: center;
            padding: 4px;
            margin: 4px 0;
            min-height: 32px;
            border: none;
            padding-left: $sst-card-value-width + 4px;
            padding-right: $sst-card-value-height + 4px;
            background-color: darken($sst-card-body-bg-color, 3%);
        }
    }
}
.dashboard-text-warning {
    color: $dashboard-text-warning;
}

.dashboard-text-success {
    color: $dashboard-text-success;
}

.dashboard-text-danger {
    color: $dashboard-text-danger;
}

@include sst-card-variant('yearly', #53514e, #868583, #b1afac, #ffffff);
@include sst-card-variant('monthly', #696969, #808080, #dfd8d5, #ffffff);
</style>
